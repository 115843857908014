import {logout} from './session'

/*
 * "Auth session expired" handler.
 */
export const handle401 = ({redirect_url: url} = {}) => logout({url}) || true

/*
 * Generic error handler.
 */
export const handleError = (status, body) => {
  if (status === 401) return handle401(body)
}
