const defineParamType = members => {
  const definedMembers = {}

  Object.defineProperty(definedMembers, 'values', {
    value: [],
    configurable: false,
    writable: false,
    enumerable: true,
  })

  members.forEach(member => {
    const value = member.toLowerCase()

    Object.defineProperty(definedMembers, member, {
      configurable: false,
      writable: false,
      enumerable: true,
      value,
    })

    definedMembers.values.push(value)
  })

  Object.freeze(definedMembers.values)

  definedMembers.includes = value => _.includes(definedMembers.values, value)

  return definedMembers
}

export default defineParamType
