import request from './request'
import {getEntGuid} from './tenancy-helper'

const requestWithEntGuid = (url, {entGuid, ...opts} = {}) => {
  // eslint-disable-next-line no-param-reassign
  if (!entGuid) entGuid = getEntGuid()
  if (!entGuid) throw new TypeError("'entGuid' is required.")
  return request(url, _.merge({headers: {'X-LOOKOUT-ENT-GUID': entGuid}}, opts))
}

export default requestWithEntGuid
