import {combineReducers} from 'redux'
import protectedDevices from './protected-devices-reducer'
import analyzedApps from './analyzed-apps-reducer'

const reducer = combineReducers({
  protectedDevices,
  analyzedApps,
})

export default reducer
