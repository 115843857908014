import {createRoot} from 'react-dom/client'
import {Global, css} from '@emotion/react'
import {globalStyles} from '@lookout/ui'
import {createReactContainer} from '../lib/utils'
import printStyles from './theme/print-styles'

// HACK: A special enforcement of adding lookout-ui global EmotionCSS styles
// with all CSS color vars etc into the page before all other UI is rendered
// TODO: To remove this once MES console is at 100% React markup, so we add
// Global component as part of default React app layout
export const initGlobalStyles = () => {
  const {container} = createReactContainer({
    className: 'lookout-global-styles',
  })
  const reactRoot = createRoot(container)
  reactRoot.render(
    <>
      <Global
        styles={css(import('modern-normalize/modern-normalize.css').toString())}
      />
      <Global
        styles={css(
          import('@lookout/fonts/noto-sans/noto-sans-400.css').toString()
        )}
      />
      <Global
        styles={css(
          import('@lookout/fonts/noto-sans/noto-sans-700.css').toString()
        )}
      />
      <Global
        styles={css(import('react-phone-number-input/style.css').toString())}
      />
      <Global styles={[globalStyles, printStyles]} />
    </>
  )
}
