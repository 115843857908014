var ReactContentView;

import Content from 'gringotts/mixins/views/content';

import ReactView from './react-view';

// A react view to be used as a top level view in Chaplin Controllers
export default ReactContentView = class ReactContentView extends Content(ReactView) {
  initialize(options) {
    if (!options.className) {
      throw new Error('ReactContentView option className is required.');
    }
    return super.initialize(...arguments);
  }

};
