var Layout,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Chaplin from 'chaplin';

import {
  trackDomClick
} from './services/mixpanel-helper';

export default Layout = class Layout extends Chaplin.Layout {
  constructor() {
    super(...arguments);
    this.openLink = this.openLink.bind(this);
  }

  openLink(event) {
    boundMethodCheck(this, Layout);
    trackDomClick(event);
    return super.openLink(event);
  }

};
