import deployment from './deployment/deployment-reducer'
import issueTrends from './issue-trends/issue-trends-reducer'
import securityCloud from './security-cloud/security-cloud-reducer'
import appAnalysis from './app-analysis/app-analysis-reducer'

export default {
  deployment,
  issueTrends,
  securityCloud,
  appAnalysis,
}
