import {assignLocation} from './location-proxy'
import {toAbsoluteUrl, serverUrlFor, destinationParams} from './utils'
import {resetChanges} from './change-tracker'

const loggedEnts = () =>
  JSON.parse(global.sessionStorage.getItem('X-LOOKOUT-ENTS') || '[]')

export const isLoggedEnt = entGuid => _.includes(loggedEnts(), entGuid)

export const saveLoggedEnt = entGuid =>
  global.sessionStorage.setItem(
    'X-LOOKOUT-ENTS',
    JSON.stringify([...loggedEnts(), entGuid])
  )

/*
 * Clears session storage and redirects to logout page.
 */
export const logout = ({url = '/logout', withPath} = {}) => {
  global.sessionStorage.removeItem('X-LOOKOUT-ENTS')
  resetChanges() // to avoid browser navigate confirmation dialog
  assignLocation(
    `${toAbsoluteUrl(serverUrlFor(url))}?${destinationParams({withPath})}`
  )
}
