import {defineAction} from 'redux-define'
import {
  defineFetchSubactions,
  createFetchSubactions,
} from './fetch-subactions-helper'

const acquisition = defineAction('acquisition')

export const SUBMIT_APP_URLS = defineFetchSubactions(
  acquisition.defineAction('submit-app-urls')
)
export const submitAppUrls = createFetchSubactions(SUBMIT_APP_URLS)
