import {handleActions} from 'redux-actions'
import {READ_GLOBAL_PROTECTED_DEVICES} from '../../../store/gds-actions'
import {Source} from '../gds-param-types'
import {merge} from '../../../lib/utils'

export const initialState = {
  deviceCountsBySource: {
    [Source.GLOBAL]: null,
  },
}

const reducer = handleActions(
  {
    [READ_GLOBAL_PROTECTED_DEVICES.SUCCESS]: (
      state,
      {payload: {source, deviceCount}}
    ) => merge({}, state, {deviceCountsBySource: {[source]: deviceCount}}),
  },
  initialState
)

export const getDeviceCountsBySource = state =>
  state.securityCloud.protectedDevices.deviceCountsBySource

export default reducer
