const {LookoutI18n} = require('@lookout/i18n')
const translations = require('../config/locales/console/en.yml')

if (!lkt.I18n) {
  lkt.I18n = new LookoutI18n()
  lkt.I18n.locale = 'en'
  lkt.I18n.translations = translations // Always load English strings
}

module.exports = lkt.I18n
