import {merge} from '../lib/utils'
import {createSubactions, hasSubactions} from './subactions-helper'

const standard = ['REQUEST', 'SUCCESS', 'ERROR']

// TODO: retire DONE subaction
const deprecated = ['DONE']

export const fetchSubactions = _.union(standard, deprecated)

export const defineFetchSubactions = namespace =>
  namespace.defineAction('FETCH', fetchSubactions)

export const createFetchSubactions = actionType =>
  createSubactions(actionType, fetchSubactions)

/*
 * Checks if current ReduxActionType action has subactions defined as
 * ReduxActionType as well.
 */
export const hasFetchSubactions = actionType =>
  hasSubactions(actionType, standard)

/*
 * Returns a reducer map that will handle fetch request, success and error
 * subactions. It should be injected into a target component reducer.
 * @param  {ReduxActionType} action
 * @param  {Function} merger is a required argument. It is a function that will
 * merge fetchState into a target components redux state.
 */
export const getFetchReducerMap = (actionType, merger) =>
  _.reduce(
    standard,
    (result, subaction) =>
      _.tap(
        result,
        map =>
          // eslint-disable-next-line no-param-reassign
          (map[actionType[subaction]] = (state, action) =>
            merger(state, {in: subaction}, action))
      ),
    {}
  )

/*
 * These are shortcuts to `getFetchReducerMap`. They will merge fetch state
 * into a target component redux state automatically. Assuming the conventional
 * names like `createState`, 'readState', 'updateState' and 'deleteState'.
 * All four are standard forms of CRUD actions that are used in most generic
 * components.
 */

export const getCreateReducerMap = actionType =>
  getFetchReducerMap(actionType, (state, createState) =>
    merge({}, state, {createState})
  )

export const getReadReducerMap = actionType =>
  getFetchReducerMap(actionType, (state, readState) =>
    merge({}, state, {readState})
  )

export const getUpdateReducerMap = actionType =>
  getFetchReducerMap(actionType, (state, updateState) =>
    merge({}, state, {updateState})
  )

export const getDeleteReducerMap = actionType =>
  getFetchReducerMap(actionType, (state, deleteState) =>
    merge({}, state, {deleteState})
  )
