import {parseJSON} from 'gringotts'
import {tipSessionBouncer} from '../services/request'
import {handleError} from './error-helper'

const parseXhrResponse = (getResponseHeader, responseText) => {
  const contentType = getResponseHeader('content-type')
  try {
    if (_.includes(contentType, 'application/json'))
      return parseJSON(responseText)
    if (_.includes(contentType, 'text/html')) return responseText
  } catch {
    return {}
  }
}

/*
 * Setups global jQuery Ajax error listeners.
 */

export const initJQueryListeners = () => {
  $(global.document).ajaxComplete((e, x, {url}) => tipSessionBouncer(url))
  $(global.document).ajaxError((e, {status, getResponseHeader, responseText}) =>
    handleError(status, parseXhrResponse(getResponseHeader, responseText))
  )
}
