var Router;

import Chaplin from 'chaplin';

import Backbone from 'backbone';

import History from './chaplin-history';

export default Router = class Router extends Chaplin.Router {
  createHistory() {
    return Backbone.history = new History();
  }

};
