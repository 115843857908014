import * as SentryBrowser from '@sentry/browser'
import {mapValuesDeep, scrubSensitiveData, isLockdownEnv} from '../lib/utils'
import {getEnt} from './tenancy-helper'

const Sentry = SentryBrowser

const sentryDsn = () => lkt.config.dsn || lkt.config.sentry_dsn

const sentryEnabled = () => !!sentryDsn() && !isLockdownEnv()

const initSentry = () => {
  if (sentryEnabled()) {
    try {
      Sentry.init({
        dsn: sentryDsn(),
        integrations: [Sentry.browserTracingIntegration()],
        ignoreErrors: [
          'AbortError',
          'ServerError',
          'NetworkError',
          'CancellationError',
          /NetworkError/,
          /Failed to fetch/,
          /Load failed/,
          /Loading CSS chunk/,
        ],
        beforeBreadcrumb: breadcrumb =>
          // skipping calls to Mixpanel from Sentry recording, as this is not our user activity (but a foreign APIs one)
          _.includes(breadcrumb.data?.url, 'mixpanel.com') ? null : breadcrumb,
        beforeSend: event =>
          sentryEnabled()
            ? mapValuesDeep(event, (value, key) =>
                _.some(['url', 'from', 'to'], target => _.includes(key, target))
                  ? scrubSensitiveData(value)
                  : value
              )
            : null,
      })
      if (lkt.cache?.admin) Sentry.setUser({admin_role: lkt.cache.admin.role})
      const info = {
        release: `mtp-console@${
          !lkt.config.release || lkt.config.release === 'master'
            ? 'latest'
            : lkt.config.release
        }+${lkt.config.version}`,
        environment: lkt.config.env,
        locale: I18n.locale,
        one_intel_console_version: lkt.config.one_intel_console_version,
      }
      Sentry.setTags(info)
      // duplicating some tags as extras for better Mixpanel UX (to see this data in the page section)
      Sentry.setExtras(info)
      const ent = getEnt()
      if (ent)
        Sentry.setExtras({ent_language: ent.language, ent_type: ent.type})
    } catch (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to initialize Sentry:', e)
    }
  } else {
    try {
      Sentry.close(0)
    } catch (e) {
      // eslint-disable-next-line no-console
      if (e) console?.error('Failed to close Sentry:', e)
    }
  }
}

export const captureException = (...args) =>
  sentryEnabled() && Sentry.captureException(...args)

export default initSentry
