import React from 'react'
import {typography, Layout, Button} from '@lookout/ui'
import logoMark from '../../assets/images/logo-mark.svg'
import {
  breakpoints,
  contentPadding,
  mobileContentPadding,
} from '../../styles/theme'
import {logout} from '../../lib/session'
import {classes} from '../../lib/utils'

const mapMessages = (messages, predicate) =>
  _.map(_.uniq(_.compact(_.castArray(messages))), predicate)

const ErrorLayout = ({
  className,
  title,
  description,
  showLogout,
  inline,
  ...props
}) => (
  <Layout
    vertical
    className={classes('error-layout', className)}
    css={{
      margin: inline ? 0 : '60px auto',
      alignItems: inline ? 'flex-start' : 'center',
      padding: inline ? 0 : contentPadding,
      [breakpoints.belowQuery({max: breakpoints.screenXsMax})]: {
        padding: inline ? 0 : mobileContentPadding,
      },
    }}
    {...props}
  >
    {!inline && (
      <img
        className="logo-mark"
        alt={I18n.t('brand.name')}
        src={logoMark}
        css={{height: 60}}
      />
    )}

    <Layout vertical css={typography.paragraph}>
      {title &&
        (inline ? (
          <h2 className="error-title" css={{marginTop: 10}}>
            {title}
          </h2>
        ) : (
          <h1 className="error-title" css={{fontSize: typography.toRem(30)}}>
            {title}
          </h1>
        ))}

      {description && (
        <div className="error-description" css={{a: {whiteSpace: 'nowrap'}}}>
          {_.isArray(description)
            ? mapMessages(description, message => (
                <p key={message}>{message}</p>
              ))
            : description}
        </div>
      )}
      <span className="contact-support-description">
        {I18n.t('error.try_again_or_contact_support', {
          support_link: I18n.t('error.customer_support_link'),
        })}
      </span>
      {showLogout && (
        <Layout vertical css={{alignItems: 'center'}}>
          <Button className="logout-button" onClick={() => logout()} link>
            {I18n.t('navigation.logout')}
          </Button>
        </Layout>
      )}
    </Layout>
  </Layout>
)

export default ErrorLayout
