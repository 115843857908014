import React from 'react';

import {
  HyperText
} from '@lookout/ui';

import ErrorView from './error-view';

export default {
  noAccess: function(options) {
    return new ErrorView(_.extend({
      title: I18n.t('error.no_access.title'),
      description: I18n.t('error.no_access.description')
    }, options));
  },
  notFound: function(options) {
    return new ErrorView(_.extend({
      title: I18n.t('error.not_found.title'),
      description: <HyperText className="support-link" content={I18n.t('error.not_found.description', {
        email_link: '{email_link}'
      })} as="p">
            <HyperText key="email_link" content={I18n.t('error.support_team')} href={I18n.t('error.email_support_link')} as="a" />
          </HyperText>
    }, options));
  },
  genericError: function(options) {
    return new ErrorView(_.extend({
      title: I18n.t('error.generic_error.title'),
      description: <>
            <HyperText className="support-link" content={I18n.t('error.report_error_message', {
        email_link: '{email_link}'
      })} as="p">
              <HyperText key="email_link" content={I18n.t('error.support_team')} href={I18n.t('error.email_support_link')} as="a" />
            </HyperText>
            <p>{I18n.t('error.protected_message.message1')}</p>
            <p>{I18n.t('error.protected_message.message2')}</p>
          </>
    }, options));
  }
};
