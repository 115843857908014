import {appDetailsPrintStyle} from '../../components/apps/app-details-style'

const printViewStyle = {
  '*, *:before, *:after': {
    printColorAdjust: 'exact',
  },
  'a[href]:after': {
    content: "''",
  },
  '.page-break, .page-break-before': {
    breakBefore: 'always',
  },
  '.page-break-after': {
    breakAfter: 'always',
  },
  '.no-print': {
    display: 'none',
  },
  '.print-header, .print-footer': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.print-header': {
    paddingBottom: 20,
    '.logo-mark': {
      height: 80,
    },
  },
  '.print-footer': {
    paddingTop: 20,
    '.logo': {
      height: 40,
    },
  },
  '.site-main': {
    marginLeft: 0,
  },
  '.container-fluid': {
    padding: 20,
  },
  img: {
    breakInside: 'avoid',
    position: 'relative',
  },
  ['.btn, .notifications, .lookout-nav-panel, .tooltip, button,' +
  ' a[type=button], .lookout-tooltip, .lookout-info-tip,' +
  '.lookout-pager-previous-button, .lookout-pager-next-button,' +
  ' .lookout-assistant-chat-button, #wootric-modal']: {
    display: 'none !important',
  },
  table: {
    '.row-action-item': {
      display: 'none',
    },
  },
}

const printStyles = {
  '@media not print': {
    '.page-break': {
      display: 'none',
    },
    '.print-header, .print-footer': {
      display: 'none',
    },
  },
  '@media print': printViewStyle,
  '.lookout-cypress-print': {
    ...printViewStyle,
    '.app-view': {
      ...appDetailsPrintStyle,
    },
  },
}

export default printStyles
