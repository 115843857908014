import handlebars from 'handlebars/runtime';

import {
  mixinHelper
} from 'gringotts';

import {
  classes
} from '../../lib/utils';

import {
  supportUrl
} from '../../services/support-helper';

import linkTemplate from '../templates/partials/external-link.hbs';

export default function(superclass) {
  var GenericErrorNotifying;
  return GenericErrorNotifying = class GenericErrorNotifying extends superclass {
    initialize() {
      mixinHelper.assertViewOrCollectionView(this);
      return super.initialize(...arguments);
    }

    // Adding another generic variant to shortcut notify functions defined in
    // gringotts Notifying mixin. This is mostly to be used in React based code
    notifyInfo(message, {className, ...opts} = {}) {
      return this.notify(message, {
        classes: classes('alert-global', className),
        navigateDismiss: true,
        ...opts
      });
    }

    // Custom MES Console error message with a link to support
    genericErrorMessage() {
      var link, text;
      link = linkTemplate({
        linkUrl: supportUrl('portal_link'),
        className: 'customer-support-error',
        label: I18n.t('error.customer_support_link')
      });
      text = I18n.t('error.try_again_or_contact_support', {
        support_link: link
      });
      return new handlebars.SafeString(text);
    }

  };
};
