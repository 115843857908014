var Application;

import Chaplin from 'chaplin';

import configureStore from './store/configure-store';

import Layout from './layout';

import Dispatcher from './dispatcher';

import Router from './router';

export default Application = class Application extends Chaplin.Application {
  initDispatcher(options) {
    return this.dispatcher = new Dispatcher(options);
  }

  initLayout(options = {}) {
    if (options.title == null) {
      options.title = this.title;
    }
    return this.layout = new Layout(options);
  }

  initMediator() {
    Chaplin.mediator.store = configureStore();
    return super.initMediator(...arguments);
  }

  initRouter(routes, options) {
    this.router = new Router(options);
    return typeof routes === "function" ? routes(this.router.match) : void 0;
  }

};
