/**
 * This file was a temporary effort to move of CSS Styles
 * This file should be retired
 * All styles should move directly to Elements
 */
/**  @deprecated */

import {breakpoints} from '@lookout/design'
import {colors} from '@lookout/ui'

export const appDetailsPrintStyle = {
  '.page-header > div': {
    padding: 0,
  },

  '.table-layout-auto > tbody > tr > td': {
    whiteSpace: 'normal',
  },

  '.app-report-heading': {
    display: 'inline',
  },

  '.app-report-created': {
    display: 'block',
  },

  '.tag-label': {
    background: `${colors.lightBlueGray300} !important`,
    color: `${colors.blueGray100} !important`,
  },

  '.app-summary-section': {
    margin: 0,
    padding: 0,
    '.app-summary': {
      flexDirection: 'row !important',
      '.col-auto': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '&:not(:first-of-type)': {
          marginLeft: 40,
        },
        '&:last-of-type': {
          flex: 20,
        },

        h4: {
          whiteSpace: 'nowrap',
          marginRight: 0,
        },
      },
    },
  },

  '.app-identification-list': {
    '.list-column': {
      flexDirection: 'row',
      '.label-cell': {
        width: 160,
        minWidth: 160,
        wordWrap: 'break-word',
      },
      '.value-cell': {
        flex: 1,
        paddingLeft: 40,
        paddingRight: 40,
        '>span': {
          wordBreak: 'normal',
        },
      },
    },
  },

  '.app-identification-list .title': {
    whiteSpace: 'nowrap !important',
  },

  '.app-generic-icon': {
    background: `${colors.lightBlueGray300} !important`,
  },

  '.app-description-content': {
    background: `${colors.lightGray200} !important`,
    maxHeight: 'none',
    overflow: 'visible',
  },
}

const appViewStyles = {
  '> section': {
    marginBottom: 50,
  },

  'th, td': {
    '.tooltip-item': {
      marginLeft: 8,
    },

    '&.app-network-activity-country, .inline-popularity-chart .tooltip-item': {
      margin: 0,
    },

    '.inline-popularity-chart': {
      '.tooltip-inner': {
        whiteSpace: 'nowrap',
      },
    },
  },

  '.table-layout-auto': {
    tableLayout: 'auto',

    '> thead, > tbody': {
      '> tr': {
        '> th, > td': {
          boxSizing: 'content-box',
          paddingRight: 40,

          [`+${breakpoints.belowQuery({max: breakpoints.screenXsMax})}`]: {
            paddingRight: 20,
          },

          '&:last-child': {
            width: '100%',
          },
        },
      },
    },

    '> thead > tr > th': {
      whiteSpace: 'nowrap',
    },

    '> tbody > tr > td': {
      verticalAlign: 'top',
    },
  },

  '.app-report-heading, .app-report-created': {
    display: 'none',
  },

  '.app-icon': {
    width: 70,
    height: 15,
    margin: '30px 28px',
  },

  '.app-blacklisted + .blacklist-buttons': {
    marginLeft: 20,
  },

  '.app-status-section': {
    marginBottom: 0,
  },

  '.app-status': {
    borderLeft: '3px solid',
    marginBottom: 50,
    maxWidth: 1000,
    paddingLeft: 20,

    '&.completed': {
      display: 'none',
    },

    '&.in_progress': {
      borderLeftColor: 'med-risk',
    },

    '&.unavailable': {
      borderLeftColor: 'high-risk',
    },

    '&.unknown': {
      display: 'none',
    },
  },

  '.app-summary': {
    '.app-os i': {
      fontStyle: 'normal',
    },

    [`.app-os-column +${breakpoints.belowQuery({
      max: breakpoints.screenMdMax,
    })}`]: {
      marginLeft: 0,
    },
  },

  '.app-identification-content': {
    display: 'flex',
    paddingTop: 10,

    '.app-icon-container': {
      marginRight: 20,
    },

    '.app-identification-list': {
      maxWidth: '75%',
    },

    td: {
      padding: '0 20px 12px 0',

      '.title': {
        color: colors.darkGray200,
      },
    },
  },

  '.app-description-content': {
    background: colors.lightGray200,
    border: 0,
    borderRadius: 0,
    boxSizing: 'border-box',
    color: colors.blueGray50,
    maxHeight: 250,
    maxWidth: '100%',
    overflow: 'auto',
    padding: 20,
    whiteSpace: 'pre-wrap',
    width: 800,
    wordBreak: 'break-word',
  },

  '.app-malware-threat': {
    ul: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    li: {
      alignItems: 'center',
      display: 'flex',
      height: 30,
    },
  },

  '.app-malware-threat-text': {
    display: 'inline-block',
    marginRight: 7,
  },

  '.app-malware-families-section': {
    marginBottom: 0,

    '.column-list': {
      marginBottom: 20,
    },

    '.app-malware-family-group': {
      marginBottom: 50,
    },
  },

  '.app-network-activity-map': {
    '.datamaps-popup-wrapper': {
      marginLeft: -90,
      marginTop: -10,
      width: 180,

      ul: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
    },
  },

  '&.android': {
    '.app-categories, .app-store-id, .app-seller, .app-cloud-services-section':
      {
        display: 'none',
      },
  },

  '&.ios': {
    '.app-icon': {
      borderRadius: 15,
    },

    '.app-malware-section, .app-permissions-section': {
      display: 'none',
    },
  },

  '.app-violation-description': {
    strong: {
      color: colors.text,
      fontWeight: 'normal',
    },
  },

  '.app-insecure-data-section': {
    '.transport-security-table-header, .storage-security-table-header': {
      h3: {},
      color: colors.black200,
    },

    '.app-transport-security-data': {
      padding: 0,
    },

    '.app-transport-security-label, .domains-table-container': {
      borderLeft: '3px solid transparent',
      boxSizing: 'border-box',
    },

    '.app-transport-security-row:not(.no-hover)': {
      '.app-transport-security-label:hover': {
        borderLeft: `3px solid ${colors.primary}`,
        cursor: 'pointer',
      },

      '&.expanded': {
        background: colors.lightGreen300,

        '.app-transport-security-data': {
          '.app-transport-security-label, .domains-table-container': {
            borderLeft: `3px solid ${colors.primary}`,
          },
        },
      },
    },

    '.app-transport-security-label': {
      display: 'flex',
      justifyContent: 'space-between',

      '> div': {
        padding: 8,

        '&:first-of-type': {
          paddingLeft: 37,
        },

        '&:last-child': {
          paddingRight: 37,
        },

        [`+${breakpoints.belowQuery({max: breakpoints.screenXsMax})}`]: {
          '&:first-of-type': {
            paddingLeft: 17,
          },

          '&:last-child': {
            paddingRight: 17,
          },
        },
      },
    },

    '.show-domain-btn': {
      padding: 0,
    },

    '.app-transport-security-label.collapsed': {
      '.hide-domain-text': {
        display: 'none',
      },
      '.show-domain-text': {
        display: 'inline',
      },
    },

    '.app-transport-security-label:not(.collapsed)': {
      '.arrow-down': {
        transform: 'rotate(-180deg)',
        transformOrigin: 'center',
      },
      '.show-domain-text': {
        display: 'none',
      },
      '.hide-domain-text': {
        display: 'inline',
      },
    },

    '.domains-table-collapse': {
      cursor: 'default',

      [`+${breakpoints.belowQuery({max: breakpoints.screenXsMax})}`]: {
        '.domains-table-container': {
          marginBottom: 0,
        },
      },
    },

    '.domains-table': {
      marginBottom: 0,
      position: 'relative',
      tableLayout: 'fixed',

      '> tbody > tr > td': {
        '&:first-of-type': {
          paddingLeft: 37,
        },

        '&:last-child': {
          paddingRight: 37,
        },

        [`+${breakpoints.belowQuery({max: breakpoints.screenXsMax})}`]: {
          '&:first-of-type': {
            paddingLeft: 17,
          },

          '&:last-child': {
            paddingRight: 17,
          },
        },
      },
    },

    '.spacer': {
      backgroundColor: colors.white,
      height: 30,
    },
  },

  '@media print': appDetailsPrintStyle,
}

export default appViewStyles
