import {breakpoints} from '@lookout/design'
import {combineReducers} from 'redux'
import {createResponsiveStateReducer} from 'redux-responsive'
import appsReducer from '../components/apps/apps-reducer'
import dashboardReducers from '../components/dashboard/dashboard-reducers'

const {screenXxsMax, screenXsMax, screenSmMax, screenMdMax, screenLgMax} =
  breakpoints

const maxima = {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax,
}

const storeReducer = combineReducers({
  browser: createResponsiveStateReducer(maxima),
  apps: appsReducer,
  ...dashboardReducers,
})

export default storeReducer
