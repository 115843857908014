import {combineReducers} from 'redux'
import multiseries from './issue-trends-multiseries-reducer'
import topIssues from './issue-trends-top-issues-reducer'
import ui from './issue-trends-ui-reducer'

const reducer = combineReducers({
  multiseries,
  topIssues,
  ui,
})

export default reducer
