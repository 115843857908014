/* eslint-disable no-unused-expressions */
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import {browserLocale} from '@lookout/i18n'
import {scrubSensitiveData, isLockdownEnv} from '../lib/utils'
import {captureException} from './sentry-helper'
import {getEnt, getEntGuid} from './tenancy-helper'

const mixpanelEnabled = () => !!lkt.config.mixpanel_token && !isLockdownEnv()

const instanceName = entGuid => entGuid && `ent-${entGuid}`

const mixpanelInitialized = () => !!mixpanel[instanceName(getEntGuid())]

const trackEvent = (eventName, opts) =>
  mixpanel[instanceName(getEntGuid())].track(eventName, opts)

const eventString = name => _.map(name.split('-'), _.upperFirst).join(' ')

/*
 * Tracks a view to show in Mixpanel.
 */
export const trackView = (viewName, {tabName, ...opts} = {}) => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  try {
    trackEvent(`View - ${eventString(viewName)}`, {
      ...opts,
      'UTC Offset': moment().utcOffset() / 60,
      ...(tabName && {'Tab Name': eventString(tabName)}),
    })
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to track view with Mixpanel:', e)
      captureException(e)
    }
  }
}

/*
 * Tracks a ChaplinJS route to show in Mixpanel.
 */
export const trackRoute = (route, withTabs = true) => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  trackView(route.controller, {
    Path: scrubSensitiveData(route.path),
    'Initial Load': !route.previous,
    ...(withTabs && {tabName: route.name.split('/').pop()}),
  })
}

/*
 * Tracks the click to show in Mixpanel.
 */
export const trackClick = (scope, desc) => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  if (!scope) return
  try {
    trackEvent(`Click - ${eventString(scope)}`, desc && {desc})
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to track click with Mixpanel:', e)
      captureException(e)
    }
  }
}

export const trackDomClick = e => {
  if (!mixpanelEnabled() || !mixpanelInitialized()) return
  const el = e.target.classList.contains('mixpanel-link')
    ? e.target
    : e.target.closest('.mixpanel-link')
  if (!el) return
  const scope = el.getAttribute('data-mixpanel-link-scope')
  const desc = el.getAttribute('data-mixpanel-link-desc')
  trackClick(scope, desc)
}

export const initMixpanel = () => {
  if (!getEnt() || !mixpanelEnabled()) return
  try {
    mixpanel.init(
      lkt.config.mixpanel_token,
      {
        ip: false,
        property_blacklist: ['$initial_referrer', '$referrer', '$current_url'],
      },
      instanceName(getEntGuid())
    )
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to initialize Mixpanel:', e)
      captureException(e)
    }
  }
  const ent = getEnt()
  try {
    mixpanel[instanceName(getEntGuid())].register({
      Env: global.location.host,
      Locale: browserLocale(),
      ...(ent && {
        'Ent Language': ent.language,
        'Ent Type': ent.type,
        'Commercial Partner': ent.commercial_partner_name,
      }),
    })
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to register Mixpanel context:', e)
      captureException(e)
    }
  }
}
