import React from 'react'
import {colors} from '@lookout/ui'
import {RiskLevel, RiskLevelName} from '../../lib/utils'

// Checks if the policy inherits its values from some parent policy (normally
// from a default device group)
export const isInherited = ({severity, actions}) =>
  severity === null && actions === null

// newly created policies in a device group have `policyGuid: null`, so we should
// match two attributes to verify policy match
export const predicate = (policy1, policy2) =>
  policy1.policyGuid === policy2.policyGuid &&
  policy1.parentGuid === policy2.parentGuid

// describes visual table sections that split policies into Unique and Inherited sets
export const SectionIndex = {
  UNIQUE_HEADER: 1,
  UNIQUE_ROW: 2,
  INHERITED_HEADER: 3,
  INHERITED_ROW: 4,
  DISABLED_HEADER: 5,
  DISABLED_ROW: 6,
}

export const contourLines = {
  before: {
    '::before': {
      border: `0.5px solid ${colors.darkGray400}`,
      height: '50%',
      content: '""',
      left: 18,
      top: '0',
      position: 'absolute',
      zIndex: 0,
    },
  },
  after: {
    '::after': {
      border: `0.5px solid ${colors.darkGray400}`,
      height: '50%',
      content: '""',
      left: 18,
      bottom: 0,
      position: 'absolute',
      zIndex: 0,
    },
  },
}

export const defaultQuery = ({sort_by}) => ({
  sort_by: 'severity',
  order: !sort_by || sort_by === 'severity' ? 'desc' : 'asc',
})

// because newly created policies in a device group have `policyGuid: null`
export const policyId = ({policyGuid, parentGuid} = {}) =>
  policyGuid || parentGuid

export const escalationId = ({classification, policy_severity}) =>
  `${classification}-${policy_severity}`

// Check the value regardless if it is annotated or not
export const isAnnotatedEqual = (source, target) =>
  _.isArray(source)
    ? _.every(source, (s, i) => isAnnotatedEqual(s, target[i]))
    : source === target || source?.value === target

// Policy Store annotated values are `{value: 'something`, source: 'some_guid'}`
export const isAnnotatedValue = input =>
  _.isObject(input) &&
  _.thru(
    _.keys(input),
    attrs =>
      !_.isEmpty(attrs) && _.isEmpty(_.difference(attrs, ['value', 'source']))
  )

export const removeAnnotations = input =>
  _.isArray(input)
    ? _.map(input, i => (isAnnotatedValue(i) ? i.value : removeAnnotations(i)))
    : _.isObject(input)
    ? _.mapValues(input, i =>
        isAnnotatedValue(i) ? i.value : removeAnnotations(i)
      )
    : input

export const predicateEscalation = (escalation1, escalation2) =>
  escalation1.classification === escalation2.classification &&
  escalation1.policy_severity === escalation2.policy_severity

export const toClientEscalations = escalations =>
  _.map(escalations, escalation => ({
    ...escalation,
    classification: _.toUpper(escalation.classification),
    policy_severity: RiskLevel[_.toUpper(escalation.policy_severity)],
  }))

export const toServerEscalations = escalations =>
  _.map(escalations, escalation =>
    _.extend({}, escalation, {
      classification: _.toLower(escalation.classification),
      policy_severity: _.toLower(RiskLevelName[escalation.policy_severity]),
    })
  )

export const mapPolicyEscalations = ({policy, escalations}) =>
  _.orderBy(
    _.filter(
      escalations,
      ({classification}) =>
        _.toLower(classification) === _.toLower(policy.classification)
    ),
    'policy_severity',
    'asc'
  )
// An extended from RouteContext that additionally define special modes for
// Policies UI components
export const PoliciesContext = React.createContext({
  // If true we will see "Inherit" options in dropdowns and other UI elements managing it
  inheritanceEnabled: false,
  // In Policies this usually is taken from RouteContext as route.query.group_guid
  groupGuid: undefined,
  // The two value are taken from the current device group `policy_group_attributes` value
  riskAndActionDisabled: false,
  configDisabled: false,
})

export const ACTION_SORT_MAP = {
  NOTHING: 0,
  CONTENT_WARN_AND_ALERT: 1,
  CONTENT_BLOCK_AND_ALERT: 2,
  QUARANTINE_SELECTIVE: 3,
  QUARANTINE: 4,
  NOTIFY: 5,
}

export const enabledState = protection =>
  protection?.enabled === null
    ? protection?.parent_settings?.enabled
    : protection?.enabled
