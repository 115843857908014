import {createSelectorCreator, defaultMemoize} from 'reselect'

// This can be used to create selectors that use deep comparison. The default
// implementation of createSelector uses shallow comparison, and since we
// always return a new object in Redux, nothing gets memoized. If shallow
// comparison is sufficient for a given reducer, the default implementation
// of create selector should be used.
const createSelector = createSelectorCreator(defaultMemoize, _.isEqual)

export default createSelector
