const isValidPrevalence = value =>
  _.isNumber(value) && _.isFinite(value) && value <= 1

const normalizePrevalence = prevalence => {
  if (isValidPrevalence(prevalence)) {
    return prevalence * 100
  }
}

/**
 * Parses and normalizes input string number.
 * @param  {String} prevalence float string number in the range of 0 to 1.
 * @return {Number} normalized round number in the range of 0 to 100.
 */
export const parsePrevalence = prevalence =>
  normalizePrevalence(parseFloat(prevalence))

/**
 * Calculates prevalence value from app count and total app count.
 * @param  {Number} appCount
 * @param  {Number} totalAppCount
 * @return {Number} normalized float number in the range of 0 to 100
 */
export const calcPrevalence = (appCount, totalAppCount) => {
  const prevalence = appCount / totalAppCount
  return normalizePrevalence(prevalence)
}

/**
 * Formats prevalence into a string with '%' char at the end.
 * @param  {Number} prevalence in the range of 0 to 100 or undefined.
 * @param  {Object} options if showZero is yes then add '0%'
 *                          for undefined prevalence.
 *                          if roundOnePercent is yes then add '<1%'
 *                          for cases when 0% < prevalence < 1%
 * @return {String} string with prevalence in the range of 0% to 100% or
 *                  undefined if prevalence is undefined
 */
export const formatPrevalence = (prevalence, options = {}) => {
  _.defaults(options, {showZero: false, roundOnePercent: true})
  if (prevalence > 0 && prevalence < 1 && options.roundOnePercent) {
    return '<1%'
  }
  if (prevalence) return I18n.numberToPercentage(prevalence)
  if (prevalence === 0 || options.showZero) {
    return I18n.numberToPercentage(0)
  }
}

export const appleStoreURL = appStoreId => {
  if (!appStoreId) {
    return
  }
  return `https://apps.apple.com/app/id${appStoreId}`
}

const analysisDescription = status =>
  I18n.t(`apps.status.analysis_status.${status}`)

const acquisitionDescription = status =>
  I18n.t(`apps.status.acquisition_status.${status}`)

const ACQUISITION_MAP = {
  UNKNOWN: 'unavailable',
  FAILED: 'unavailable',
  NOT_FOUND: 'unavailable',
  NOT_SUPPORTED: 'unavailable',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'in_progress',
}

const ANALYSIS_MAP = {
  COMPLETED() {
    return {
      status: 'completed',
    }
  },
  IN_PROGRESS(acquisition_status) {
    if (
      acquisition_status &&
      _.indexOf(_.keys(ACQUISITION_MAP), acquisition_status) >= 0
    ) {
      return {
        status: ACQUISITION_MAP[acquisition_status],
        description: acquisitionDescription(acquisition_status),
      }
    }
    return {
      status: 'in_progress',
      description: analysisDescription('IN_PROGRESS'),
    }
  },
  FAILED() {
    return {
      status: 'unavailable',
      description: analysisDescription('FAILED'),
    }
  },
  UNDEFINED(acquisition_status) {
    if (
      acquisition_status &&
      _.indexOf(_.keys(ACQUISITION_MAP), acquisition_status) >= 0
    ) {
      return {
        status: ACQUISITION_MAP[acquisition_status],
        description: acquisitionDescription(acquisition_status),
      }
    }
    return {
      status: 'unknown', // will be hidden in the UI
    }
  },
}

export const getAppStatus = (analysis, acquisition) => {
  const analysis_status = analysis?.status || 'UNDEFINED'
  let acquisition_status = acquisition?.status
  if (acquisition_status && _.startsWith(acquisition_status, 'NOT_SUPPORTED')) {
    acquisition_status = 'NOT_SUPPORTED'
  }
  if (_.indexOf(_.keys(ANALYSIS_MAP), analysis_status) >= 0) {
    return ANALYSIS_MAP[analysis_status](acquisition_status)
  }
  return ANALYSIS_MAP.UNDEFINED()
}
