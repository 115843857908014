import Backbone from 'backbone'
import validation from 'backbone-validation'
import {swissAjax} from 'gringotts'
import Application from '../application'
import routes from '../routes'
import {appUrlRoot, urlJoin} from './utils'
import {initJQueryListeners} from './jquery-helper'

const loadValidationMessages = async () => {
  _.each(_.keys(validation.messages), msgKey => {
    const str = I18n.t(`error.validation.${msgKey}`, {missingBehavior: 'empty'})
    if (str) validation.messages[msgKey] = str
  })
}

/*
 * Initialize all global settings for the application.
 */
export const initializeLegacy = () => {
  initJQueryListeners()
  Backbone.ajax = swissAjax.ajax
  loadValidationMessages()
}

/*
 * Instantiates an application by given type and routes.
 * @param  {Object} application       Normally it's a Chaplin.Application.
 * @param  {Function} routes          Custom routes.
 */
export const createApp = (TargetApplication = Application) =>
  new TargetApplication({
    title: I18n.t('brand.name'),
    controllerSuffix: '-controller',
    root: urlJoin(appUrlRoot(), '/'),
    routes,
    // chaplin has a reversed boolean meaning of the func result https://github.com/chaplinjs/chaplin/blob/master/src/chaplin/views/layout.coffee#L127
    // if it's a function it rather should be named shouldNotSkipRouting, the code
    // should be read accordingly
    skipRouting: (href, el) =>
      !el.classList.contains('noscript') &&
      (el.classList.contains('chaplin-link') ||
        !el.closest('.skip-chaplin-routing')),
    scrollTo: null, // we use in-house scroll in Controller.beforeAction
  })
