var Dispatcher;

import Backbone from 'backbone';

import Chaplin from 'chaplin';

import confirmNavigation from './lib/confirm-navigation';

export default Dispatcher = class Dispatcher extends Chaplin.Dispatcher {
  loadController(name, handler) {
    var fileName;
    if (name === Object(name)) {
      return handler(name);
    }
    // eslint-disable-next-line coffee/no-unused-vars
    fileName = name + this.settings.controllerSuffix;
    return import(
      /* webpackExclude: /\.spec\.coffee$/ */
      `./controllers/${fileName}`
    ).then(function(Controller) {
      return handler(Controller.default);
    });
  }

  dispatch(route, params, {ignoreUnsavedChanges} = {}) {
    if (!ignoreUnsavedChanges && this.currentRoute && this.hasChanges(...arguments)) {
      // users may click browser's Back/Forward while confirm dialog is visible,
      // let's prevent all History activities until after dialog is closed
      return Backbone.history.stop();
    } else {
      return super.dispatch(...arguments);
    }
  }

  // Returns True if there are unsaved changes. It shows in-app confirm dialog,
  // and user response is delivered using `callback func
  hasChanges() {
    return confirmNavigation((agreed) => {
      var fragment;
      Backbone.history.start({
        silent: true
      });
      fragment = _.compact([this.currentRoute.path, this.currentRoute.query]).join('?');
      if (agreed) {
        if (fragment === Backbone.history.fragment) {
          return super.dispatch(...arguments);
        } else {
          // in case if users went far away with browser Back/Forward clicks
          // and agreed to leave unsaved changes, we should load the URL path
          // that users travelled onto while confirm dialog was visible.
          // But, to avoid showing confirm dialog again, we got to call
          // `super.dispatch` directly while getting proper arguments by
          // tricking Router with a `match` that has "happened".
          this.unsubscribeEvent('router:match', this.dispatch);
          this.subscribeEventOnce('router:match', (...a) => {
            return super.dispatch(...a);
          });
          Backbone.history.loadUrl(Backbone.history.fragment);
          return this.subscribeEvent('router:match', this.dispatch);
        }
      } else {
        // in case if users went far away with browser Back/Forward clicks,
        // let's bring them back to current route path with unsaved changes
        return Backbone.history.navigate(fragment);
      }
    });
  }

};
