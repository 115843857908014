import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'

const mes = defineAction('mes')
const dashboard = mes.defineAction('dashboard')
const appAnalysis = dashboard.defineAction('app-analysis')

export const CANCEL_SAGA = appAnalysis.defineAction('CANCEL_SAGA')
export const cancelSaga = createAction(CANCEL_SAGA.ACTION)

export const SET_CATEGORY = appAnalysis.defineAction('SET_CATEGORY')
export const setCategory = createAction(SET_CATEGORY.ACTION)

export const SET_SOURCE = appAnalysis.defineAction('SET_SOURCE')
export const setSource = createAction(SET_SOURCE.ACTION)
